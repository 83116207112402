export function getLastUrlSegment (thePath) {
  return thePath.substring(thePath.lastIndexOf('/') + 1)
}

export function serializeErrorMessage (error) {
  if (process.env.NODE_ENV !== 'production') {
    console.error(error)
  }
  if (typeof error !== 'undefined') {
    if (typeof error.errors !== 'undefined') {
      let validationMessages = error.errors
      if (typeof validationMessages === 'string') {
        return validationMessages
      } else if (Object.keys(validationMessages).length) {
        let errorMessages = '<ul>'
        for (let i = 0; i < Object.keys(validationMessages).length; i++) {
          errorMessages = errorMessages + '<li>' + validationMessages[Object.keys(validationMessages)[i]] + '</li>'
        }
        errorMessages = errorMessages + '</ul>'
        return errorMessages
      } else {
        return error
      }
    } else if (typeof error.message !== 'undefined') {
      return (typeof error.error  !== 'undefined' && error.error === 'invalid_grant') || error.message === 'The user credentials were incorrect.' || error.message === 'Client authentication failed' ? 'Kombinasi email/username & password salah' : error.message
    } else if (typeof error !== 'undefined') {
      return error
    }
  } else if (typeof error === 'string') {
    return error
  } else {
    return 'ada yang salah, silahkan coba kembali.'
  }
}

export function goBack (self) {
  if (self.$route.query.redirect !== undefined) {
    self.$router.push(self.$route.query.redirect)
  } else {
    window.history.back()
  }
}